<template>
    <div>
        <v-btn
            @click.stop="showPanel = !showPanel"
            :block="block"
            :class="buttonClass"
            :height="inline ? 'null' : height"
            :icon="hasIcon && label == null"
            :large="large"
            :small="small"
            :title="title">
            <v-icon v-if="hasIcon && label == null" :large="large" :small="small">{{ leftIcon || rightIcon }}</v-icon>
            <v-row v-else-if="!inline" class="mt-0 mb-3">
                <v-col v-if="leftIcon != null" class="mx-0 mx-auto px-auto" cols="12">
                    <v-icon :large="large" :small="small">{{ leftIcon || rightIcon }}</v-icon>
                </v-col>
                <v-col v-if="label != null" class="ma-0 pa-0" cols="12">
                    <div>{{ label }}</div>
                </v-col>
            </v-row>
            <span v-else>
                <v-icon v-if="leftIcon != null" :large="large" :small="small" :left="label != null">{{ leftIcon }}</v-icon>
                {{ label }}
                <v-icon v-if="rightIcon != null" :large="large" :small="small" :right="label != null">{{ rightIcon }}</v-icon>
            </span>
        </v-btn>

        <v-navigation-drawer
            v-model="showPanel"
            app
            :bottom="bottom"
            :clipped="clipped"
            :hide-overlay="hideOverlay"
            right
            style="height: 100%;"
            :width="navWidth">
            <v-toolbar dense>
                <v-btn
                    icon
                    small
                    title="Close"
                    @click="showPanel = false">
                    <v-icon>{{ ($vuetify.breakpoint.mobile && bottom) ? 'mdi-arrow-down' : 'mdi-arrow-right' }}</v-icon>
                </v-btn>
                <v-toolbar-title>{{ sidebarLabel || label }}</v-toolbar-title>
                <v-spacer />
                <v-btn
                    v-if="canRefresh"
                    icon
                    small
                    title="Refresh"
                    @click="refresh">
                    <v-icon small>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar>

            <v-alert v-model="errorMsg" dismissible type="error">{{ errorMsg }}</v-alert>

            <div v-if="!isLengthyArray(asyncExternalParties)">
                <BT-Btn
                    bladeName="external-party-options"
                    block
                    leftIcon="mdi-connection"
                    label="Connect"
                    title="Connect to a third party" />
            </div>
            <v-list 
                v-else-if="asyncItems != null"
                dense
                height="auto"
                class="overflow-y-auto">
                <template v-for="(item, index) in asyncItems">
                    <v-list-item :key="index">
                        <v-list-item-avatar>
                            <v-img :src="imageURL(item.externalParty + '.png')" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-card-title>{{  capitalizeWords(item.externalParty) }}</v-card-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <span v-if="item.externalID != null">
                                <v-icon small left>mdi-check</v-icon>Uploaded
                            </span>
                            <v-btn v-else @click.stop="pushToParty(item)">
                                <v-icon small left>mdi-cloud-upload</v-icon>Upload
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="dividers" :key="'d' + index" />
                </template>
            </v-list>

            <v-overlay :value="loadingMsg != null" absolute key="6" class="text-center">
                <v-progress-circular indeterminate size="32" />
                <p>{{ loadingMsg }}</p>
            </v-overlay>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    name: 'BT-Sidebar-External-Links',
    data: function() {
        return {
            asyncItem: null,
            asyncItems: [],
            asyncExternalParties: [],
            errorMsg: null,
            loadingMsg: null,
            showPanel: false
        }
    },
    props: {
        block: {
            type: Boolean,
            default: false
        },
        bottom: {
            type: Boolean,
            default: true
        },
        buttonClass: {
            type: String,
            default: 'ma-1'
        },
        canRefresh: {
            type: Boolean,
            default: true,
        },
        clipped: {
            type: Boolean,
            default: true
        },
        dividers: {
            type: Boolean,
            default: true
        },
        hideOverlay: {
            type: Boolean,
            default: true
        },
        inline: {
            type: Boolean,
            default: false
        },
        itemID: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        leftIcon: {
            type: String,
            default: null
        },
        navigation: {
            type: String,
            default: null
        },
        navWidth: {
            type: String,
            default: '350'
        },
        rightIcon: {
            type: String,
            default: null
        },
        sidebarLabel: {
            type: String,
            default: null
        },
        small: {
            type: Boolean,
            default: false
        },
        syncNavigation: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        }
    },
    watch: {
        showPanel: function(val) {
            if (val) {
                this.pullItems();
            }
        }
    },
    computed: {
        canAuthEdit() {
            return this.$canEdit('sign-over-lnks');
        },
        hasIcon() {
            return this.leftIcon != null || this.rightIcon != null;
        },
        height() {
            return this.small ? 30 : (this.large ? 80 : 'auto');
        },
        possibleExternalParties() {
            return this.$BlitzIt.navigation.findExternalParties(this.syncNavigation);
        },
    },
    methods: {
        async pullItems(refresh = false) {
            this.asyncExternalParties = [];

            try {
                this.loadingMsg = 'Searching For External Parties';
                
                if (this.isLengthyArray(this.possibleExternalParties)) {
                    var res = await this.$BlitzIt.store.getAll('external-party-credentials', null, refresh, null, null);
                    
                    if (this.isLengthyArray(res)) {
                        res.forEach(element => {
                            var cred = this.possibleExternalParties.find(x => x.party == element.externalPartyOptionID);
                            if (cred != null) {
                                this.asyncExternalParties.push(cred);
                            }
                        });
                    }
                }

                this.loadingMsg = 'Pulling Integrations';
                
                this.asyncItem = await this.$BlitzIt.store.get(this.navigation, this.itemID, { includeDetails: false, properties: 'ID,ExternalReferences' }, true, null, null);

                this.asyncItems = this.asyncExternalParties.map(x =>
                {
                    var r = {
                        externalParty: x.party,
                        externalID: null
                    };

                    if (this.isLengthyArray(this.asyncItem.externalReferences)) {
                        var extRef = this.asyncItem.externalReferences.find(y => y.externalParty == x.party);
                        if (extRef != null) {
                            r.externalID = extRef.externalID;
                        }
                    }

                    return r;
                });
            }
            catch (err) {
                this.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async pushToParty(extItem) {
            try {
                this.loadingMsg = 'Pushing';
                var rItem = {
                    localID: this.asyncItem.id,
                    externalItemID: extItem.externalID,
                    externalParty: extItem.externalPartyID,
                    externalPartyID: extItem.externalPartyID
                };

                var r = await this.$BlitzIt.api.patch(this.syncNavigation, [rItem], null, '/patch?partyID=' + extItem.externalParty);
                
                var returnItem = r.data.data[0];

                if (returnItem.message != null) {
                    this.errorMsg = returnItem.message;
                }
                else {
                    extItem.externalID = returnItem.externalItemID;
                    this.asyncItem.externalReferences.push(rItem);
                }

                // var listRes = await this.$BlitzIt.store.pushSyncItem(this.syncNavigation, this.itemID, extItem.externalPartyID);
                // var returnItem = Array.isArray(listRes) ? listRes[0] : listRes;
                
                // if (returnItem.message != null) {
                //     this.errorMsg = returnItem.message;
                // }
                // else {
                //     extItem.externalItemID = returnItem.externalItemID;
                //     this.asyncItem[extItem.externalPropertyProp] = extItem.externalItemID;
                // }
            }
            catch (err) {
                this.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        refresh(refresh = true) {
            this.errorMsg = null;
            this.pullItems(refresh);
        },
    }
}
</script>